export const ROUTES = {
  DASHBOARD: '/',
  PRODUCTS: '/products',
  VENDING_MACHINES: '/vending-machines',
  SLOT_BOOKING: '/slot-booking',
  SLOTS: '/slots',
  REFILL: '/refill',
  STOCK: '/stock',
  VENDORS: '/vendors',
  AUTH: '/auth',
  RECOVERY_PASSWORD: '/auth/recovery',
  CREATE_PASSWORD: '/auth/create-password',
  PRODUCT_ASSIGNMENT: '/product-assignment',
  SALES: '/sales',
  MACHINE: '/machine',
  HISTORY: '/history',
  MACHINE_STATUS: '/status',
};

export const NESTED_ROUTES = {
  REFILL_REQUESTS: 'refill-requests',
  STOCK_MANAGEMENT: 'stock-management',
  OVERVIEW: 'overview',
  DETAILS: 'details',
  PER_PRODUCT: 'per-product',
  PER_MACHINE: 'per-machine',
};
