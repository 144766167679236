import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ROUTES, NESTED_ROUTES } from 'configs';
import SalesOverview from './SalesOverview';
import SalesDetailsView from './SalesDetailsView';

const Router = () => (
  <Switch>
    <Route path={`${ROUTES.SALES}/${NESTED_ROUTES.OVERVIEW}`}>
      {(props) => (
        <SalesOverview />
      )}
    </Route>
    <Route path={`${ROUTES.SALES}/${NESTED_ROUTES.DETAILS}`}>
      {(props) => (
        <SalesDetailsView/>
      )}
    </Route>
    <Route path={`${ROUTES.SALES}`}>
      {(props) => <Redirect to={`${ROUTES.SALES}/${NESTED_ROUTES.OVERVIEW}`} {...props} />}
    </Route>
  </Switch>
);

export default Router;
