import React from 'react';
import { string } from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES, ERRORS, DEFAULT_ROUTE } from 'configs';

import { Header, Error, PrivateRoute } from 'components';
import {
  HomePage,
  VendorsPage,
  ProductsPage,
  SlotsPage,
  StockPage,
  VendorSlotsPage,
  VendorRefillPage,
  ProductAssignmentPage,
  SalesPage,
  MachinePage,
  SalesAndRefillHistoryPage,
  MachineStatusPage,
} from 'pages';

const MainRouter = ({ firstName, lastName, role }) => (
  <>
    <Header
      firstName={firstName}
      lastName={lastName}
      role={role}
    />
    <Switch>
      <Route exact path={ROUTES.DASHBOARD}>
        <Redirect to={DEFAULT_ROUTE[role]} />
      </Route>
      <Route exact path={ROUTES.AUTH}>
        <Redirect to={DEFAULT_ROUTE[role]} />
      </Route>
      <Route roles={['admin']} path={ROUTES.PRODUCTS}>
        <ProductsPage />
      </Route>
      <Route path={ROUTES.VENDING_MACHINES}>
        <HomePage name="VENDING_MACHINES" />
      </Route>
      <PrivateRoute roles={['admin']} path={ROUTES.SLOT_BOOKING}>
        <SlotsPage />
      </PrivateRoute>
      <PrivateRoute roles={['admin', 'vendor', 'observer']} path={ROUTES.SALES}>
        <SalesPage />
      </PrivateRoute>
      <PrivateRoute roles={['admin']} path={ROUTES.MACHINE}>
        <MachinePage />
      </PrivateRoute>
      <PrivateRoute roles={['admin', 'refiller']} path={ROUTES.STOCK}>
        <StockPage name="STOCK" />
      </PrivateRoute>
      <PrivateRoute roles={['admin']} path={ROUTES.VENDORS}>
        <VendorsPage />
      </PrivateRoute>
      <PrivateRoute roles={['vendor', 'observer']} path={ROUTES.SLOTS}>
        <VendorSlotsPage />
      </PrivateRoute>
      <PrivateRoute roles={['vendor']} path={ROUTES.REFILL}>
        <VendorRefillPage />
      </PrivateRoute>
      <PrivateRoute roles={['vendor']} path={ROUTES.PRODUCT_ASSIGNMENT}>
        <ProductAssignmentPage />
      </PrivateRoute>
      <PrivateRoute roles={['vendor']} path={ROUTES.HISTORY}>
        <SalesAndRefillHistoryPage />
      </PrivateRoute>
      <PrivateRoute roles={['admin']} path={ROUTES.MACHINE_STATUS}>
        <MachineStatusPage />
      </PrivateRoute>
      <Error code={ERRORS.NOT_FOUND} />
    </Switch>
  </>
);

MainRouter.propTypes = {
  firstName: string,
  lastName: string,
  role: string,
};

MainRouter.defaultProps = {
  firstName: '',
  lastName: '',
  role: '',
};

export default MainRouter;
