import React from 'react';
import { noop } from 'lodash/fp';
import {
  string, bool, func, number,
} from 'prop-types';

import FormElementContainer, { BaseInput, BaseInputMultiline, useInput, OutlineInput } from 'components/BaseInput';

import { StyledEyeIcon } from './styled';


const TextInput = ({
  width, error, label, errorText, placeholder, register,
  type, name, required, maxLength, defaultValue, variant, disabled, min, max, step, multiline
}) => {
  const {
    currentType, changeType, inputErrorText,
  } = useInput(type, errorText, error);

  const inputProps = {
    placeholder,
    name,
    defaultValue,
    type: currentType,
    inputRef: register,
    inputProps: {
      maxLength, min, max, step,
    },
    id: name,
    endAdornment: type === 'password' && (
      <StyledEyeIcon
        onClick={changeType}
      />
    ),
  };

  let input;
  if(variant === 'outlined'){
    input = <OutlineInput {...inputProps} /> 
  } else if(multiline === true){
    input = <BaseInputMultiline {...inputProps} multiline={multiline} />
  } else {
    input = <BaseInput {...inputProps} />
  }

  return (
    <FormElementContainer
      error={!!error}
      label={label}
      errorText={inputErrorText}
      width={width}
      required={required}
      name={name}
      variant={variant}
      disabled={disabled}
    >
      {input}
    </FormElementContainer>
  );
};

TextInput.propTypes = {
  width: string,
  error: string,
  required: bool,
  label: string,
  errorText: string,
  placeholder: string,
  register: func,
  type: string,
  name: string.isRequired,
  maxLength: number,
  min: number,
  max: number,
  step: number,
  defaultValue: string,
  variant: string,
  disabled: bool,
  multiline: bool,
};

TextInput.defaultProps = {
  width: '100%',
  error: '',
  label: '',
  errorText: '',
  placeholder: '',
  variant: '',
  register: noop,
  type: 'text',
  required: false,
  maxLength: null,
  min: null,
  max: null,
  step: null,
  defaultValue: null,
  disabled: false,
  multiline: false,
};

export default TextInput;
