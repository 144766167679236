import React from 'react';
import { arrayOf, string, node } from 'prop-types';
import { includes, intersection } from 'lodash/fp';
import { useQuery } from '@apollo/react-hooks';

import { USER_ROLE } from './queries';

const PrivateComponent = ({ roles = [], availableGroups = [], children }) => {
  const { data } = useQuery(USER_ROLE);

  return includes(roles, data.role) || intersection(availableGroups, data.availableGroups).length > 0 ? (
    <>
      {children}
    </>
  ) : null;
};

PrivateComponent.propTypes = {
  roles: arrayOf(string),
  availableGroups: arrayOf(string),
  children: node,
};

PrivateComponent.defaultProps = {
  roles: [],
  availableGroups: [],
  children: null,
};

export default PrivateComponent;
